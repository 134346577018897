import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/Layout"
import {useTranslation} from 'gatsby-plugin-react-i18next';
import { IPageData } from "../model/QueryData";
import ProjectView from "../components/ProjectView";
import { Project } from "../model/Project";
import MainButtonLink from "../components/MainButtonLink";
import DynamicProjectImage from "../components/DynamicProjectImage";

export default function Projects(props: IPageData) {
  const { data } = props;

  const { t } = useTranslation();
  return(
    <Layout>
      <div className="section">
        <section>
        <header>
          <h1 className="title is-2 has-text-centered mainTitle">{t('upcoming')}</h1>
        </header>
        {data.allMarkdownRemark.edges.map(post => (
          <div className="container">
            <header className="headerProject">
              <h1 className="memberName title is-3 titleProject">{post.node.frontmatter.title}</h1>
            </header>
            <br />

            <div className="is-flex-direction-row columns">
              <div className="column">
                <p dangerouslySetInnerHTML={{__html: post.node.html}} />
                <DynamicProjectImage filename={post.node.frontmatter.photo} alt={post.node.frontmatter.title} />
              </div>
              <div className="column">
              <p className="memberName is-3">When: {post.node.frontmatter.period}</p>
              <p className="memberName is-3">Where: {post.node.frontmatter.location}</p>
              <p className="memberName is-3">For: {post.node.frontmatter.age}</p>
              <p className="memberName is-3">How many: {post.node.frontmatter.participants}</p>
              <p  className="questionaireText">{post.node.frontmatter.description}</p>
              <br />
              <MainButtonLink url={post.node.frontmatter.link} title={t('yourOpinion')} />
              </div>
              </div>
          </div>
        ))}

        </section>
      </div>
    </Layout>
  )
}
export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          location: {ne: null},
          language: {eq: $language}
        }
      },
      sort: {fields: frontmatter___date}
    ) {
      edges {
        node {
          html
          excerpt
          frontmatter {
            slug
            photo
            title
            location
            date(formatString: "DD/MM/YYYY")
            language
            description
            period
            age
            location
            participants
            link
          }
        }
      }
    }
  }
`
