import { graphql, StaticQuery } from "gatsby";
import React from "react";
import { GatsbyImage } from 'gatsby-plugin-image';

interface DynamicImageProps {
  filename: string,
  alt: string
}

const DynamicProjectImage = (props: DynamicImageProps) => (
  <StaticQuery
    query={graphql`
      query {
        images: allFile(filter: {
          internal: {mediaType: {in: ["image/png", "image/jpeg"]}},
          relativePath: {regex: "/projects.*/"}
        }) {
          edges {
            node {
              relativePath
              name
              childImageSharp {
                gatsbyImageData(layout: FIXED, width: 400)
              }
            }
          }
        }
      }
    `}
    render={data => {
      const image = data.images.edges.find((n: any) => {
        return n.node.relativePath.includes(props.filename);
      });
      if (!image) {
        return null;
      }

      return (
        <GatsbyImage
          alt={props.alt}
          image={image.node.childImageSharp.gatsbyImageData}
        />
      );
    }}
  />
);

export default DynamicProjectImage;
